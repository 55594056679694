import React from "react";
import {HStack, Select, SimpleGrid, Tag, Text, Tooltip} from "@chakra-ui/react";
import {getDataServer} from "../Configure";
import CharacterIcon from "./CharacterIcon";
import CharacterView from "./CharacterView";
import {Character, CharacterStub} from "../Type/Character";

export default function CharacterAppContainer() {
    let [selectedCharacter, setSelectedCharacter] = React.useState<Character | null>(null);
    let [origCharacterStubs, setOrigCharacterStubs] = React.useState<CharacterStub[]>([]);
    let [characterStubs, setCharacterStubs] = React.useState<CharacterStub[]>([]);

    let [sortingType, setSortingType] = React.useState<string>("default")
    let receiving = false;

    React.useEffect(() => {
        async function getCharacterList() {
            if (receiving) return;
            receiving = true;
            try {
                let response = await fetch(getDataServer() + 'characters.json')
                let data = await response.json()
                setOrigCharacterStubs(data)
                setCharacterStubs(data)
            } catch (err: any) {
                console.log(err)
            }
            receiving = false;
        }

        getCharacterList().then()
    }, [])

    let view = (<CharacterView character={selectedCharacter} setSelectedCharacter={setSelectedCharacter}/>)

    const sortByName = (a: CharacterStub, b: CharacterStub): number => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    };

    const sortByHeight = (a: CharacterStub, b: CharacterStub): number => {
        if (a.height < b.height) return -1;
        if (a.height > b.height) return 1;
        return 0;
    };

    const sortByOppai = (a: CharacterStub, b: CharacterStub): number => {
        if (a.oppai < b.oppai) return -1;
        if (a.oppai > b.oppai) return 1;
        return 0;
    };

    const sortByUnion = (a: CharacterStub, b: CharacterStub): number => {
        if (a.union < b.union) return -1;
        if (a.union > b.union) return 1;
        return 0;
    };

    function sortList(type: string) {
        setSortingType(type)
        if (type == "default") {
            setCharacterStubs(origCharacterStubs)
        } else if (type == "name") {
            console.log("Sorting by name")
            setCharacterStubs([...characterStubs].sort(sortByName))
        } else if (type == "height") {
            console.log("Sorting by height")
            setCharacterStubs([...characterStubs].sort(sortByHeight))
        } else if (type == "oppai") {
            console.log("Sorting by oppai")
            setCharacterStubs([...characterStubs].sort(sortByOppai))
        } else if (type == "union") {
            setCharacterStubs([...characterStubs].sort(sortByUnion))
        } else {
            console.error("unknown type:" + type)
        }
    }

    let list = (<>
        <HStack>
            <Text width={"5em"}>
                정렬 방식:
            </Text>
            <Select defaultValue={sortingType} width={"auto"} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                sortList(e.target.value ?? "default")
            }}>
                <option value={"default"}>기본 정렬</option>
                <option value={"name"}>이름 순</option>
                <option value={"height"}>키 순</option>
                <option value={"oppai"}>가슴 크기 순</option>
                <option value={"union"}>소속 순</option>
            </Select>
            {sortingType == "oppai" ? <Tooltip
                label={"가슴 크기는 공식 데이터가 없기 때문에, 모델에 등록되어 있는 가슴쪽 물리(바스트 모핑)가 수정하는 부위의 크기를 대략적으로 측정해서 계산합니다. 수치가 0인경우 모델이 바스트 모핑 데이터를 가지고 있지 않아 계산에 실패한 경우입니다."}><Tag>공식
                데이터 아님</Tag></Tooltip> : <></>}
        </HStack>
        <SimpleGrid minChildWidth='190px' spacing='10px'>
            {characterStubs.map((item) => (
                <CharacterIcon characterStub={item} sortingType={sortingType}
                               setSelectedCharacter={setSelectedCharacter}
                               key={item.code_name}></CharacterIcon>
            ))}
        </SimpleGrid>
    </>)

    return (
        <>
            {selectedCharacter != null ? view : list}
        </>
    )
}