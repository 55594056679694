import {Box, Center, ChakraProps, Flex, Image, LinkBox, LinkOverlay, useDisclosure} from "@chakra-ui/react";
import React from "react";

interface ClickToZoomImageProps extends ChakraProps {
    src: string | undefined
}

export default function ClickToZoomImage(props: ClickToZoomImageProps) {
    let modalProps = useDisclosure();

    let view = (<Box position={'fixed'} top={'0px'} left={'0px'} width={'100vw'} height={'100vh'} zIndex={'1'}
                      background={'#111111EE'}>
        <LinkBox>
            <LinkOverlay width={'100vw'} height={'100vh'} href={"#/"} onClick={() => {
                modalProps.onClose()
            }}></LinkOverlay>
            <Center>
                <Image objectFit={'contain'} display={'flex'} width={'100vw'} height={'100vh'} src={props.src}></Image>
            </Center>
        </LinkBox>
    </Box>)

    return (
        <>
            {modalProps.isOpen ? view : ''}
            <LinkBox {...props}>
                <LinkOverlay href={props.src} target={"_blank"}></LinkOverlay>
                <Image height={'100%'} objectFit={'contain'} src={props.src}></Image>
            </LinkBox>

        </>
    )
}