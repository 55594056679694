import React, {useEffect} from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Heading,
    HStack,
    IconButton,
    LinkBox,
    LinkOverlay,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Stack,
    StackDivider
} from "@chakra-ui/react";
import {ArrowLeftIcon} from "@chakra-ui/icons";
import ClickToZoomImage from "../Base/ClickToZoomImage";
import {
    Character,
    Costume,
    GetCostumeImage,
    GetImagePath,
    GetVideoPath,
    GetVoicePath,
    Illust,
    Talk,
    TalkContainer,
    Voice
} from "../Type/Character";
import VoiceBox from "./VoiceBox";
import IllustBox from "./IllustBox";
import TalkBox from "./TalkBox";

export default function CharacterView({character, setSelectedCharacter}: {
    character: Character | null,
    setSelectedCharacter: React.Dispatch<React.SetStateAction<Character | null>>
}) {
    const [selectedCostume, setSelectedCostume] = React.useState<Costume | null>();

    React.useEffect(() => {
        if (character == null) {
            setSelectedCostume(null)
            return
        }
        setSelectedCostume(character.costumes[0])
    }, [character])

    const [isOpenIntroduction, setOpenIntroduction] = React.useState(false);

    const initialVolume: number = parseFloat(localStorage.getItem('volume') || '0.5');
    const [volume, setVolume] = React.useState<number>(initialVolume);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function checkAndMakeVoice(data_id: string, voice_category: string) {
        if (character != null && character.voices.hasOwnProperty(data_id)) {
            const list = character.voices[data_id]!
            return <>
                <Box fontSize={'xl'}>{voice_category}</Box>
                {list.map((item: Voice) => (
                    <VoiceBox volume={volume} voice_path={GetVoicePath(character?.code_name, item.fn)}
                              text={item.text}/>))}
            </>
        }
        return <></>
    }

    function getTypeAsString(container: TalkContainer) {
        if (container.type == -1) {
            return "영지 SD 캐릭터 말풍선"
        }
        if (container.type == 4) {
            return '특별 스토리'
        }
        if (container.type == 20) {
            return `영지 대화 (인연 레벨 ${container.loveLevel} 이상)`
        }
        return `인연 레벨 ${container.loveLevel} 이상 스토리 에버톡 (${container.type == 3 ? '스토리 진입 전' : '스토리 진입 후'})`
    }

    return (
        <Box>
            <Box fontSize={'3xl'}>
                <IconButton marginRight={'8px'} aria-label={'뒤로가기'} icon={<ArrowLeftIcon/>} onClick={() => {
                    setSelectedCharacter(null)
                }}/>
                {character?.name}
            </Box>
            <Divider marginTop={'8px'} marginBottom={'8px'}></Divider>

            <Box display={'flex'} width={'100%'} position={'relative'} alignItems={'baseline'}>
                <Box position={'absolute'}>
                    <Box height={'1.2em'}>{character?.nickname}</Box>
                    <Box height={'1.1em'} fontSize={'2xl'}>{character?.name}</Box>
                    {
                        character?.artifact_name !== "undefined" ? <Box>: {character?.artifact_name}</Box> : <></>
                    }
                    <Box marginTop={'4px'}>{selectedCostume?.name}</Box>
                    <Box>{selectedCostume?.obtain_from}</Box>
                </Box>
                <Box position={'absolute'} right={'0px'} maxW={'200px'} background={''}>
                    {
                        !isOpenIntroduction ? <>
                            소속: {character?.profile?.union}<br/>
                            신장: {character?.profile?.height} cm<br/>
                            생일: {character?.profile?.birthday}<br/>
                            취미: {character?.profile?.hobby}<br/>
                            특기: {character?.profile?.speciality}<br/>
                            좋아하는 것: {character?.profile?.likes}<br/>
                            싫어하는 것: {character?.profile?.dislikes}<br/>
                            CV: {character?.profile?.cv}<br/>
                        </> : <></>
                    }
                    <a href={"#/"} onClick={() => {
                        setOpenIntroduction(!isOpenIntroduction)
                    }}><b>- 자기 소개 -</b></a>
                    {
                        isOpenIntroduction ?
                            <Box whiteSpace={'pre-wrap'}>
                                {character?.profile?.introduction}
                            </Box> : <></>
                    }
                </Box>
                <Box position={'absolute'} bottom={'0px'} right={'0px'}>
                    <HStack>
                        {
                            character?.costumes.map((item: Costume) =>
                                (<LinkBox zIndex={2}>
                                        <LinkOverlay href={"#/"} onClick={() => {
                                            setSelectedCostume(item)
                                        }}></LinkOverlay>
                                        <img alt={item.name} width={'96px'} height={'96px'}
                                             src={GetCostumeImage(character?.code_name, item.icon)}/>
                                    </LinkBox>
                                )
                            )
                        }
                    </HStack>
                </Box>
                {/*<Box position={'absolute'} bottom={'0px'}>*/}
                {/*    <Button onClick={() => {*/}
                {/*        window.open(GetCostumeImage(character?.code_name, selectedCostume?.id))*/}
                {/*    }}>이미지 새 창에서 열기</Button>*/}
                {/*</Box>*/}
                <Box margin={'0 auto'}>
                    {<ClickToZoomImage height={'900px'}
                                       src={GetCostumeImage(character?.code_name, selectedCostume?.id)}></ClickToZoomImage>}
                </Box>
            </Box>
            <Divider marginTop={'5px'} marginBottom={'5px'}></Divider>
            <Box fontSize={'2xl'}>보이스</Box>텍스트를 누르면 보이스가 재생됩니다, 재생중에 같은 대사를 다시 누르면 재생이 종료됩니다.<br/>볼륨바 (모든 정령이 공유합니다)
            <Slider aria-label='slider-ex-1' min={0} max={100} defaultValue={initialVolume*100} onChangeEnd={(val) => {
                setVolume(val / 100);
                // 로컬 스토리지에 새로운 볼륨 값을 저장
                localStorage.setItem('volume', (val / 100).toString());
            }}>
                <SliderTrack>
                    <SliderFilledTrack/>
                </SliderTrack>
                <SliderThumb/>
            </Slider>
            {
                <>
                    {checkAndMakeVoice("Greeting", "로비 입장")}
                    {checkAndMakeVoice("new_year", "새해")}
                    {checkAndMakeVoice("valentine", "발렌타인")}
                    {checkAndMakeVoice("spring", "봄")}
                    {checkAndMakeVoice("summer", "여름")}
                    {checkAndMakeVoice("halloween", "할로윈")}
                    {checkAndMakeVoice("christmas", "크리스마스")}
                    {checkAndMakeVoice("birthday", `${character?.name} 생일`)}
                    {checkAndMakeVoice("user_birthday", `구원자 생일`)}
                    {checkAndMakeVoice("anniversary", `1주년`)}
                    {checkAndMakeVoice("Normal", `로비 보이스`)}
                    {checkAndMakeVoice("Love", `로비 보이스 (호감도 높음)`)}
                    {checkAndMakeVoice("Leave", `자리비움 보이스`)}
                    {checkAndMakeVoice("TouchSp", `스폐셜 터치`)}
                    {checkAndMakeVoice("TouchSp2", `스폐셜 터치 (호감도 높음)`)}
                    {checkAndMakeVoice("Mail", `안 읽은 편지 존재`)}
                    {checkAndMakeVoice("Hero", `강화 가능한 정령 존재`)}
                    {checkAndMakeVoice("Town", `영지쪽 확인해야 할 것 있음`)}
                    {checkAndMakeVoice("Manage1", `정령 상세창 진입시`)}
                    {checkAndMakeVoice("Manage2", `정령 프로필 진입시`)}
                    {checkAndMakeVoice("GreetingArk", `방주 진입시`)}
                    {checkAndMakeVoice("TouchArk", `방주에서 터치시`)}
                    {checkAndMakeVoice("Equip", `예장 장착`)}
                    {checkAndMakeVoice("Entry", `파티 참가`)}
                    {checkAndMakeVoice("Victory", `전투 승리`)}
                    {checkAndMakeVoice("Ultimate", `궁극기`)}
                    {checkAndMakeVoice("Title", `타이틀`)}
                    {checkAndMakeVoice("PartJobStart", `알바 시작`)}
                    {checkAndMakeVoice("PartJobFinish", `알바 완료`)}
                    {checkAndMakeVoice("BE", `배드엔딩`)}
                    {checkAndMakeVoice("NE", `노멀엔딩`)}
                    {checkAndMakeVoice("TE", `트루엔딩`)}
                    {checkAndMakeVoice("ShopEnter", `상점 입장`)}
                    {checkAndMakeVoice("ShopExit", `상점 퇴장`)}
                    {checkAndMakeVoice("ShopBuy", `상점 구매`)}
                    {checkAndMakeVoice("ShopTouch", `상점 터치`)}
                    {checkAndMakeVoice("ShopTouchSp", `상점 스폐셜 터치`)}
                    {checkAndMakeVoice("Shop", `상점 기타(상점 변경, 방치 등)`)}
                </>
            }
            <Divider marginTop={'5px'} marginBottom={'5px'}></Divider>
            <Box fontSize={'2xl'}>일러스트</Box>움직이는 영상이 있는경우, 클릭하면 영상으로 전환됩니다.
            {
                character?.illusts.map((item: Illust) => (
                    <IllustBox image_path={GetImagePath(character?.code_name, item.id)}
                               video_path={GetVideoPath(character?.code_name, item.id)}></IllustBox>
                ))
            }
            <Divider marginTop={'5px'} marginBottom={'5px'}></Divider>
            <Box fontSize={'2xl'}>에버톡</Box>
            {
                character?.evertalks.map((container: TalkContainer) => (
                    <Card marginBottom={'10px'}>
                        <CardHeader>
                            <Heading size='md'>{getTypeAsString(container)}</Heading>
                        </CardHeader>

                        <CardBody>
                            <Stack divider={<StackDivider/>} spacing='4'>
                                {container.talks.map((item: Talk) => (
                                    <TalkBox code_name={character!.code_name} talk={item}></TalkBox>
                                ))}
                            </Stack>
                        </CardBody>
                    </Card>
                ))
            }
            <Divider marginTop={'5px'} marginBottom={'5px'}></Divider>
            <Box fontSize={'2xl'}>영지 대화</Box>
            {
                character?.towntalks.map((container: TalkContainer) => (
                    <Card marginBottom={'10px'}>
                        <CardHeader>
                            <Heading size='md'>{getTypeAsString(container)}</Heading>
                        </CardHeader>

                        <CardBody>
                            <Stack divider={<StackDivider/>} spacing='4'>
                                {container.talks.map((item: Talk) => (
                                    <TalkBox code_name={character!.code_name} talk={item}></TalkBox>
                                ))}
                            </Stack>
                        </CardBody>
                    </Card>
                ))
            }
        </Box>
    )
}