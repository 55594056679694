import {
    Button, HStack, Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure, VStack
} from "@chakra-ui/react";
import React from "react";

export default function DisclaimerModal() {

    const modalProps = useDisclosure()

    const okString = ["대철희가 DB를 정상화 한다", "이게 돈이 되나요?"]


    React.useEffect(() => {
        modalProps.onOpen()
    }, [])

    return (
        <Modal {...modalProps}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader><b>살려만 다오!</b></ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    EverDB는 스토리 및 일러스트등 게임의 캐릭터 요소를 중심으로 다루는 <b style={{'color': '#AA5555'}}>비공식</b> 에버소울 데이터베이스입니다.<br/>
                    유출, 미실장을 포함한 <b style={{'color': '#AA5555'}}>스포일러</b> 위험 자료가 있습니다. 주의해주세요.<br/><br/>
                    EverDB에 있는 모든 자료의 저작권은<br/>
                    <b>"NINEARK Cooperation"</b>에게 있습니다.<br/><br/>
                    EverDB는 <Link href={'https://www.gg.go.kr/contents/contents.do?ciIdx=679&menuId=2457'} textColor={'#88BB88'}>경기도 천년 서체</Link>를 사용합니다.
                </ModalBody>
                <ModalFooter>
                <VStack width={"auto"}>
                        <HStack>
                            <Button onClick={modalProps.onClose} colorScheme='blue' mr={3}>
                                {okString[Math.floor(Math.random()*okString.length)]}
                            </Button>
                        </HStack>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}