import {Global} from '@emotion/react'

const Fonts = () => (
    <Global
        styles={`
    @font-face {
        font-family: '경기천년체';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./Title_Light.woff') format('woff');
    }
    
    @font-face {
        font-family: '경기천년체보통';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./Title_Medium.woff') format('woff');
    }
      `}
    />
)

export default Fonts