import {getDataServer} from "../Configure";

export interface CharacterStub {
    code_name: string,
    name: string,
    height: number,
    oppai: number,
    union: string,
    is_wip: boolean
}

export function getIconFromCodeName(code_name: string) {
    return getDataServer() + `Character/${code_name}/icon.png`
}

export interface Profile {
    height: number,
    birthday: string,
    constellation: string,
    hobby: string,
    speciality: string,
    likes: string,
    dislikes: string,
    introduction: string,
    cv: string,
    union: string
}

export interface Costume {
    name: string,
    obtain_from: string,
    id: string,
    icon: string
}

export function GetCostumeImage(code_name: string | undefined, id: string | undefined) {
    if (code_name == undefined || id == undefined) return "";
    return getDataServer() + `Character/${code_name}/images/${id}.png`
}

export interface Voice {
    fn: string,
    text: string
}

export interface VoiceContainer {
    [type: string]: Voice[]
}

export function GetVoicePath(code_name: string | undefined, fn: string | undefined) {
    if (code_name == null || fn == null) return "";
    return getDataServer() + `Character/${code_name}/voices/${fn}`
}

export interface Illust {
    id: string
}

export function GetImagePath(code_name: string | undefined, id: string | undefined) {
    if (code_name == undefined || id == undefined) return "";
    return getDataServer() + `Character/${code_name}/images/${id}.png`
}

export function GetVideoPath(code_name: string | undefined, id: string | undefined) {
    if (code_name == undefined || id == undefined) return "";
    return getDataServer() + `Character/${code_name}/videos/${id}.mp4`
}

export interface Talk {
    text: string,
    sender: string,
    name: string
}

export interface TalkContainer {
    loveLevel: number,
    type: number,
    talks: Talk[]
}

export interface Character {
    code_name: string,
    name: string,
    nickname: string,
    artifact_name: string,
    summon_ment: string,
    summon_ment_after: string,
    profile: Profile
    costumes: Costume[],
    voices: VoiceContainer,
    illusts: Illust[],
    evertalks: TalkContainer[],
    towntalks: TalkContainer[]
}