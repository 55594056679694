export function getDataServer() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://127.0.0.1:8001/'
    } else {
        return 'https://everdb.maid-yuzu.xyz/db/'
    }
}

export function getVersion() {
    return "1.12.151"
}

export function getRandomErrorMessage() {
    return "벨레드가 먼지를 털다가 서버가 고장난것 같아요, 다시 시도해보세요."
}