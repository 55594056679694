import React, {useEffect} from "react";
import {Link, Tag, Tooltip} from "@chakra-ui/react";

export default function VoiceBox({voice_path, text, volume}: { voice_path: string, text: string, volume: number }) {
    const audio = new Audio()

    useEffect(() => {
        audio.oncanplay = function () {
            audio.play().then()
        }
    }, [])

    function getCoveredText() {
        if (text == 'EmptyString') {
            return <Tooltip
                label={"오디오 파일은 있지만, 아직까지 대사와 연결되지 않은 보이스 데이터입니다. 아직 제대로 추출되지 않았거나, 대사 내용이 화면에 나올 필요가 없거나, 게임내에서 사용되지 않은 데이터일 수 있습니다."}><Tag>텍스트
                정보 없음</Tag></Tooltip>
        }
        if (voice_path.includes("Manage01")) {
            return <Tooltip label={"게임 내부에 텍스트 정보가 존재하지 않아 임시로 청음한 텍스트입니다"}>{text}</Tooltip>
        }
        return text
    }

    return (
        <>
            <Link href={"#/"} textColor={'#AAAAAA'} onClick={() => {
                if (!audio.paused) {
                    audio.pause()
                    audio.fastSeek(0)
                    return
                }
                audio.volume = volume
                audio.src = voice_path
                audio.play().then()
            }}>{getCoveredText()}</Link>
            <Link marginLeft={'8px'} onClick={() => {
                window.open(voice_path, '_blank')?.focus();
            }}>
                <Tag>다운로드</Tag>
            </Link>
            <br/>
        </>

    )
}