import * as React from "react"
import {useEffect} from "react"
import {
    Button,
    ButtonGroup,
    Center,
    ChakraProvider,
    Container,
    Flex,
    Image,
    Spacer,
    Text,
    Tooltip,
} from "@chakra-ui/react"
import {ColorModeSwitcher} from "./ColorModeSwitcher"
import CharacterAppContainer from "./Container/CharacterAppContainer";
import theme from './theme'
import Fonts from "./Fonts";
import DisclaimerModal from "./DisclaimerModal";
import {getVersion} from "./Configure";

export const App = () => {
    const characterContainer = (<CharacterAppContainer/>)

    const [usingContainer, setUsingContainer] = React.useState<any>(characterContainer)

    useEffect(() => {
        // 브라우저의 스크롤 복원 기능을 비활성화
        const originalScrollRestoration = window.history.scrollRestoration;
        window.history.scrollRestoration = 'manual';

        return () => {
            // 컴포넌트가 언마운트되면 원래 설정으로 되돌림
            window.history.scrollRestoration = originalScrollRestoration;
        };
    }, [])

    return (
        <ChakraProvider theme={theme}>
            <Fonts/>
            <DisclaimerModal></DisclaimerModal>
            <Container maxWidth="container.xl" minHeight="100vh" maxHeight='100vh'>
                <Flex minHeight="48px" marginBottom={'6px'}>
                    <Center>
                        <Image borderRadius='full' boxSize='32px' src="icon192.png"></Image>
                        <Text marginLeft="6px" fontSize="xl">EverDB</Text>
                        <Text marginTop={"0.2em"} fontSize={"xs"}> ({getVersion()})</Text>
                        <ButtonGroup marginLeft={'16px'} size='md' variant={'ghost'} spacing={'2'}>
                            <Button onClick={() => {
                                setUsingContainer(characterContainer)
                            }}>정령</Button>
                        </ButtonGroup>
                    </Center>
                    <Spacer/>
                    <Center>
                        <ButtonGroup marginLeft={'16px'} size='md' variant={'ghost'} spacing={'2'}>
                            <Tooltip label={'공식에서 제공하는 팬용 구글 드라이브 입니다. 원본(고화질) 데이터를 얻을 수 있습니다.'}><Button onClick={() => {
                                window.open('https://drive.google.com/drive/folders/1U6Li3zIV78vywrbk5ETxfW84WidmkU3M', '_blank')?.focus();
                            }}>공식 팬키트</Button></Tooltip>
                            <Tooltip label={'주로 정령의 성능 관련 데이터를 제공하는 사이트입니다.'}><Button onClick={() => {
                                window.open('https://db.nekoi.xyz/', '_blank')?.focus();
                            }}>에버소울 DB</Button></Tooltip>
                        </ButtonGroup>
                        <ColorModeSwitcher justifySelf="flex-end"/>
                    </Center>
                </Flex>
                {usingContainer}
            </Container>
        </ChakraProvider>
    )
}