import {Avatar, Card, Image, Grid, GridItem, Heading, Tag, Text, Tooltip} from "@chakra-ui/react";
import {getIconFromCodeName, GetImagePath, Talk} from "../Type/Character";
import React from "react";

export default function TalkBox({code_name, talk}: { code_name: string, talk: Talk }) {
    function replaceImageTags(text: string) {
        // 정규식을 사용하여 <display:image_file_name> 패턴을 찾습니다.
        const regex = /<(\w*):(\w*)>/g;
        // 주어진 text를 분석하고, 해당 패턴을 <img> 태그로 대체합니다.
        const mapped = text.split(regex).map((part, index) => {
            if (index % 3 === 1) {
                const type = part;
                const cmd = text.split(regex)[index + 1];
                console.log(type)
                console.log(cmd)
                if (type == 'display') {
                    return <img key={index} src={GetImagePath(code_name, cmd)} alt={cmd}/>;
                } else if (type == 'camera') {
                    return <Tag>카메라 흔들림</Tag>
                } else {
                    return <Tooltip label={`아직 처리되지 않은 스크립트 데이터 타입이 여기에 위치하고 있습니다 ${type}, ${cmd}`}><Tag>?</Tag></Tooltip>
                }
            }
            if (index % 3 === 2) return "";
            return part;
        });

        return mapped.map((value) => {
            if(typeof value == "string") {
                return value.trim().split("\n").map((part) => {
                    return <>{part.trim()} <br/></>
                })
            } else {
                return value
            }
        })
    }

    return (
        <Grid templateAreas={`"avatar name"
                                "avatar text"`}
              gridTemplateRows={'auto 1fr'}
              gridTemplateColumns={'auto 1fr'}>
            <GridItem area={'avatar'} marginRight={'8px'}>
                <Avatar src={getIconFromCodeName(talk.sender)}></Avatar>
            </GridItem>
            <GridItem area={'name'}>
                <Heading size={'xs'}>{talk.name}</Heading>
            </GridItem>
            <GridItem area={'text'}>
                <Text>{talk.text == "CENSORED" ? <Image src={"https://everdb.maid-yuzu.xyz/db/censored.webp"}></Image> : replaceImageTags(talk.text)}</Text>
            </GridItem>
        </Grid>
    )
}