import {
    Button,
    Card,
    CardBody,
    Center,
    Image,
    LinkBox,
    LinkOverlay,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";
import {getDataServer, getRandomErrorMessage} from "../Configure";
import {Character, CharacterStub, getIconFromCodeName} from "../Type/Character";

export default function CharacterIcon({characterStub, sortingType, setSelectedCharacter}: {
    characterStub: CharacterStub,
    sortingType: string,
    setSelectedCharacter: React.Dispatch<React.SetStateAction<Character | null>>
}) {
    const modalProps = useDisclosure()

    return (
        <>
            <Modal {...modalProps}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>오류 발생!</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {getRandomErrorMessage()}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={modalProps.onClose}>
                            방송켜라! 해명해라! 이것저것 보상해라!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <LinkBox>
                <Card width={'190px'} height={'222px'}>
                    <CardBody>
                        <Image width={'160px'} src={getIconFromCodeName(characterStub.code_name)}
                               marginBottom={'8px'}></Image>
                        <Center>{characterStub.name}
                            {sortingType == "height" ? `(${characterStub.height})` : ""}
                            {sortingType == "oppai" ? `(${Math.round(characterStub.oppai)})` : ""}
                            {sortingType == "union" ? `(${characterStub.union})` : ""}
                            {characterStub.is_wip ? " (더미 데이터)" : ""}</Center>
                    </CardBody>
                    <LinkOverlay href={"#/"} onClick={async () => {
                        try {
                            let response = await fetch(getDataServer() + `Character/${characterStub.code_name}/info.json`)
                            let data = await response.json()
                            setSelectedCharacter(data)
                        } catch {
                            modalProps.onOpen()
                        }
                    }}></LinkOverlay>
                </Card>
            </LinkBox>
        </>
    )
}