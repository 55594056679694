import {Box, Image} from "@chakra-ui/react";
import React, {useState} from "react";

export default function IllustBox({image_path, video_path}: { image_path: string, video_path: string }) {
    const [isVideo, setIsVideo] = useState(false)
    const [internalURL, setInternalURL] = useState("")

    return <Box display={'flex'} width={'100%'} position={'relative'} alignItems={'baseline'}>
        <Box position={'absolute'} display={isVideo ? 'block' : 'none'}>
            <video autoPlay={true} muted={true} onClick={() => {
                setIsVideo(false)
            }} onCanPlayThrough={() => {
                setIsVideo(true)
            }} src={internalURL}>
                <source src={internalURL}/>
            </video>
        </Box>
        <Image src={image_path} loading={'lazy'} onClick={() => {
            if(internalURL != "") {
                setIsVideo(true)
                return
            }
            setInternalURL(video_path)
        }}></Image>
    </Box>
}